// Cookies Context
// This allows us to access the cookie object via prams if a component is using this 
import React from 'react'
 
const CookiesContext = React.createContext(null)

export const withCookies = Component => props => (
    <CookiesContext.Consumer>
      {cookies => <Component {...props} cookies={cookies} />}
    </CookiesContext.Consumer>
  )
  
export default CookiesContext