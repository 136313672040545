// Gives requirements to gain access to a given page based on auth level 
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import AuthUserContext from './context'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../routes'
import {withCookies} from '../Cookies'
 
const withAuthorization = (condition,skip_force_password_check= false) => Component => {
  class WithAuthorization extends React.Component {

    componentDidMount() {
      // Gets the auth user from firebase
        this.listener = this.props.firebase.onAuthUserListener(
            authUser => {
              if(authUser.isMFA && Boolean(this.props.cookies.get('2FA')) !== true){
                this.props.history.push(ROUTES.MFA_SIGN_IN)
              }  
              // // If user first login make them setup there profile, set password ect. 
              // if (skip_force_password_check === false && authUser.required_profile_setup === true) {
              //   this.props.firebase.doSignOut()
              //   this.props.history.push(ROUTES.PROFILE_SETUP)
              // }
              // else if (skip_force_password_check === false && authUser.require_password_reset === true) {
              //  // If we a doing password reset or a force require password reset then redirec them to the correct page
              //   this.props.history.push(ROUTES.FORCE_SET_PASSWORD)
              // } else if
              else if(!condition(authUser)) {
                  // Check the condition if it failed kick the user to login screen
                  this.props.history.push(ROUTES.SIGN_IN)
              }
            }, 
            // if no auth user then send to sing in page
            () => this.props.history.push(ROUTES.SIGN_IN),
          )
    }

    componentWillUnmount() {
   //   this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            condition(authUser) ? <Component {...this.props} authUser={authUser} /> : null
          }
        </AuthUserContext.Consumer>
      )
    }
  }

  return compose(
    withRouter,
    withCookies,
    withFirebase,
  )(WithAuthorization)
}

export default withAuthorization 
