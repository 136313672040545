import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { withAuthentication  } from './components/Session'
import * as  ROUTES from './routes'
import './scss/style.scss' 

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

//Pages
const Login = React.lazy(() => import('./views/Auth/Login'))
const ForceResetPassword = React.lazy(() => import('./views/Auth/ForceResetPassword'))
const ForgottenPassword = React.lazy(() => import('./views/Auth/ForgottenPassword'))
const ProfileSetup = React.lazy(() => import('./views/Auth/ProfileSetup'))
const Actions = React.lazy(() => import('./components/Actions'))
const MFAVerify = React.lazy(() => import('./views/Auth/MFAVerify'))
const MFACreation = React.lazy(() => import('./views/Auth/MFACreation'))
const GameView = React.lazy(() => import('./views/GameView/GameView'))

const SuperUserHelpCenter = 'https://pocketsizedhands.atlassian.net/servicedesk/customer/portal/2/topic/e40877fc-fff8-47e8-a0a6-d51c7ad13c97'
const CompanyAdminHelpCenter = 'https://pocketsizedhands.atlassian.net/servicedesk/customer/portal/2/topic/765f7682-eab7-4120-9eb3-161c7237f96a'
const UserHelpCenter = 'https://pocketsizedhands.atlassian.net/servicedesk/customer/portal/2/topic/3af77819-e0db-4ed5-bdb3-b4961c39c77f'

class App extends Component {

  constructor(props) {
    super(props)
 
    this.state = {
      authUser: null,
    }
  }
  

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(
 
      authUser => {
         authUser
          ? this.authUser = authUser 
          : this.authUser = null
      },
    )
  }

   
  componentWillUnmount() {
    this.listener()
  }

  
  render() {
    return (

        <HashRouter>

            <React.Suspense fallback={loading}>
            <Switch> 
                <Route path={ROUTES.HELP_CENTER_SUPER_USER} component={() => { 
                        window.location.href = SuperUserHelpCenter
                     }}/>
                <Route path={ROUTES.HELP_CENTER_COMPANY_ADMIN} component={() => { 
                        window.location.href = CompanyAdminHelpCenter
                     }}/>
                <Route path={ROUTES.HELP_CENTER_USER} component={() => { 
                        window.location.href = UserHelpCenter
                     }}/>                     
                     
                <Route exact path={ROUTES.SIGN_IN} name="Login Page" component={Login} /> 
                <Route exact path={ROUTES.PASSWORD_FORGET}  name="Forgotten Password" component={ForgottenPassword} /> 
                <Route exact path={ROUTES.FORCE_SET_PASSWORD}  name="Force Set Password" component={ForceResetPassword} /> 
                <Route exact path={ROUTES.PROFILE_SETUP} name="Profile Setup" component={ProfileSetup} /> 
                <Route exact path={ROUTES.MFA_SETUP} name="MFA Creation" component={MFACreation} /> 
                <Route exact path={ROUTES.MFA_SIGN_IN} name="MFA Verify" component={MFAVerify} /> 
                <Route exact path={ROUTES.ACTIONS}  name="" component={Actions} /> 
                <Route exact path={ROUTES.GAMEVIEW}  name="" component={GameView} /> 
                
                <Route path= "/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
        </HashRouter> 
    )
  }
}

export default withAuthentication(App)
