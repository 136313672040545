// Firebase Context
// This allows us to access the firebase object via prams if a component is using this 
import React from 'react'
 
const FirebaseContext = React.createContext(null)

export const withFirebase = Component => props => (
    <FirebaseContext.Consumer>
      {firebase => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
  )
  
export default FirebaseContext