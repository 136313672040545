//////////////////////////////////////
// Firebase
// Use: Interaction with Firebase API 
/////////////////////////////////////


import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/functions' 
import 'firebase/remote-config' 
import 'core-js/es/array'
import 'firebase/performance'



/**
 * Firebase config data from .env file 
 */
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL:process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,    
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
} 

/**
 * Authorization role level of a user 
 */
export const AUTH_LEVEL = {
  SUPER_USER: 2,
  COMPANY_ADMIN: 1,
  USER: 0,
}

/**
 * Firebase 
 * Main API to talk directly to the firebase API  
 * @class 
 */
export class Firebase {

  
    /** @constructs */
    constructor() { 
      //  Init the Firebase app. 
      // This needs to be done first thing in the app
      firebase.initializeApp(firebaseConfig)
       
      // Get a ref to all the firebase apps which allows us to access them thought the project
      this.analytics = firebase.analytics() 
      this.db = firebase.database() 
      this.storage = firebase.storage()
      this.auth = firebase.auth() 
      this.functions = firebase.functions()
      this.authUserChange = null
      this.authUserMetadataRef = null
      this.perf = firebase.performance()
      this.remoteConfig = firebase.remoteConfig()
      this.remoteConfig.settings.minimumFetchIntervalMillis = 3600000
       
      this.remoteConfig.defaultConfig = {
        'MASTER_PAGE': '{}'
      }
      this.remoteConfig.fetchAndActivate()
      .then(() => {
        // ...
      })
      .catch(() => {
        // ...
      })
      // when a local env use the emulator endpoints
      if(process.env.NODE_ENV !== 'production'){
          this.db.useEmulator('localhost',9000) 
          // I don't know why auth uses a different syntax to setup it's emulator but it just does
          this.auth.useEmulator('http://localhost:9099/') 
          this.functions.useEmulator('localhost',5001)
      }
  } 

  getRemoteConfig(value){
    return this.remoteConfig.getValue(value)
  }


  /* 
      Auth api  
  */
  
  /**
   * Complete a Login 
   * 
   * @param {String} email - Email to login with 
   * @param {String} password - password to login with
   */
  doSignInWithEmailAndPassword = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password);

  /**
   * Sign out current user
   */
  doSignOut = () => {
    // clean up all our cookies
    document.cookie.split(';').forEach(function(c){ 
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
    })
    this.auth.signOut()
  }
  
  /**
   *  Competes a password update for logged in user
   * 
   * @param {String} password - New password for the user  
   */
  doPasswordUpdate = password =>
      this.auth.currentUser.updatePassword(password);

  /**
   * Sends a reset password email to email address
   * 
   * @param {String} email - Email address to reset account for
   */
  doPasswordResetEmail = email =>
      this.auth.sendPasswordResetEmail(email);
  

    /**
   * Sends a reset password email to email address
   * 
   * @param {String} email - Email address to reset account for
   */
  doUpdateProfile (firstName, lastName,uid) {

    return this.db.ref(`users/${uid}`)
    .update({
      name: `${firstName} ${lastName}`,
      firstName: firstName,
      lastName: lastName,
      required_profile_setup:false,
      require_password_reset:false
    })
  }
      
  /**
   * Listen for changes to the auth user
   * 
   * @param {authUserCallback} OnAuthFound - Callback called when we get a authorized back
   * @param {authUserCallback} onAuthFailed - Callback called when no auth user found or has been logged out
   */
  onAuthUserListener = (OnAuthFound, onAuthFailed) => {

    //Firebase observer to the auth call change
    this.auth.onAuthStateChanged(authUser => {
 
      // Remove the listen from meta data
      // We do this as we don't know if a auth user has been found  
      if (this.authUserChange) {
        this.authUserMetadataRef.off('value', this.authUserChange)
      }

      if(authUser){
        // Check if refresh is required.
        this.addAuthUserMetaDataListen(authUser)

        // Now we have the auth user get the data from within the database
        this.attachedDatabaseDataToUser(authUser, OnAuthFound, onAuthFailed)
      }else{
        if(onAuthFailed !== undefined){
          onAuthFailed()
        }
      }      
    })
  };
    
  /**
   * Get the last sign in time from user
   */
  getLastSignedIn = () => this.auth.currentUser.metadata.lastSignInDate

  /*
      Database api 
  */

  //TODO Need to handle this better for different company admin. How do we only get X company users
  /**
   * Gets all users in DB
   * NOTE: THIS IS ONLY ACCESSIBLE BY SUPER_ADMINS else the request will fail
   */
  getAllUsers = () => this.db.ref('users');

  getAllCompanyUsers = (companyUid) => {

    return this.db.ref(`companies/${companyUid}/members`)
    .once('value')
    .then(snapshot => {   
      var promiseArray = []   
      snapshot.forEach((childSnapshot) => {
        var id = childSnapshot.key
        promiseArray.push(
          this.db.ref(`users/${id}`)
          .once('value')
          .then((value)=>{
            return {uid:id,...value.val()}
          }))

      })
      return Promise.all(promiseArray)
      .then((values)=>{ 
        return values
      })
    }) 
  };

  getAllDepartmentUsers = (departmentUid) => {

    return this.db.ref(`departments/${departmentUid}/members`)
    .once('value')
    .then(snapshot => {   
      var promiseArray = []   
      snapshot.forEach((childSnapshot) => {
        var id = childSnapshot.key
        promiseArray.push(
          this.db.ref(`users/${id}`)
          .once('value')
          .then((value)=>{
            if(value.val()!== undefined){
              return {uid:id,...value.val()}
            }
            return null
          }))

      })
      return Promise.all(promiseArray)
      .then((values)=>{ 
        return values.filter(function (el) {
          return el != null
        }) 
      })
    }) 
  };

  
  /**
   * Returns the data from database for given user 
   * Note that only SUPER_ADMINS, COMPANY_ADMINS who are from same company and the given user can access this data
   * @param {String} uid - Auth user id
   */
  GetUser = uid => this.db.ref(`users/${uid}`);

  /**
   * Set the flag to users who need to reset their password 
   * 
   * @param {String} uid - Auth User Id
   * @param {Boolean} shouldReset - If the user needs to reset their password 
   */
  doSetForcePasswordResetState = (uid,shouldReset) =>{
    return this.db.ref(`users/${uid}/require_password_reset`)
    .set(
      shouldReset
    )
  }

  /**
   * Returns the auth level rank for the user 
   * 
   * @param {String} uid - Auth user ID 
   */
  userAuthLevel = uid => this.db.ref(`users/${uid}/authLevel`);

  /**
   * Returns the company data from database 
   * 
   * @param {String} uid - ID for the company
   */
  company = uid => this.db.ref(`companies/${uid}`);
   
  /**
   * Returns the company name from database 
   * 
   * @param {String} uid - ID for the company
   */
     companyName = uid => this.db.ref(`companies/${uid}/name`);
   
  /**
   * Returns the department name
   * 
   * @param {String} uid - ID for the department
   */
   departmentName = uid => this.db.ref(`departments/${uid}/name`);

  /**
   * Gets all companies
   * Note this is only accessible by SUPER_USERS
   */
  companies = () =>  this.db.ref('companies');

  /**
   * Return a given companies name
   * Note - Only return by users within this company + super admins 
   *  
   * @param {String} uid - Id for given company
   */
  companyName = (uid) =>  this.db.ref(`companies/${uid}/name`);

  /**
   * Gets User data from the Database and combined it with the Auth User
   * 
   * @param {Object} authUser - Auth user from Firebase 
   * @param {authUserCallback} OnAuthFound - Callback to be fired with user data
   * @param {authUserCallback} onAuthFailed - Callback called if no user could be found
   */
  attachedDatabaseDataToUser(authUser, OnAuthFound, onAuthFailed) {
    this.GetUser(authUser.uid)
      .once('value')
      .then(snapshot => {
        const dbUser = snapshot.val()

        // merge auth and db user
        // This allows us to access both auth and database data in on object
        authUser = {
          uid: authUser.uid,
          email: authUser.email,
          ...dbUser,
        }
        this.analytics.setUserProperties({COMPANY: dbUser.company})
        this.analytics.setUserProperties({DEPARTMENT: dbUser.department})
        this.analytics.setUserProperties({IATA: dbUser.IATA})
        this.analytics.setUserProperties({REQUIRED_TRAINING: dbUser.requiredTraining})
        this.analytics.setUserProperties({ROLE: dbUser.role})


        OnAuthFound(authUser)
      }).catch((_err) => {
        if(onAuthFailed != null){
          onAuthFailed()
        }
      })
    return authUser
  }

  /**
   * Creates a listen to any updates to the user which may occur 
   * @param {Object} authUser - Auth user from Firebase 
   */
  addAuthUserMetaDataListen(authUser) {
    this.authUserMetadataRef = firebase.database().ref('metadata/' + authUser.uid + '/refreshTime')
    this.authUserChange = (_snapshot) => {
      // Force refresh to pick up the latest custom claims changes.
      // Note this is always triggered on first call. Further optimization could be
      // added to avoid the initial trigger when the token is issued and already contains
      // the latest claims.
      authUser.getIdToken(true)
    }
    // Subscribe new listener to changes on that node.
    this.authUserMetadataRef.on('value', this.authUserChange)
  }
}

export default Firebase

/**
 * This callback type is called `authUserCallback` and is displayed as a global symbol.
 *
 * @callback authUserCallback
 * @param {number} responseCode
 * @param {String} responseMessage
 */
