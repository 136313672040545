import React from 'react'


export const LANDING = '/'
export const SIGN_IN = '/login'
export const DASHBOARD = '/dashboard'
export const MFA_SETUP = '/mfa-setup'
export const MFA_SIGN_IN = '/mfa-verify'
export const PASSWORD_FORGET = '/password-forget'
export const SIGN_UP = '/register'
export const ACTIONS = '/actions'
export const FORCE_SET_PASSWORD = '/force-set-password'
export const PROFILE_SETUP = '/profile-setup'
export const USER_MANAGEMENT = '/user-management'
export const COMPANY_MANAGEMENT = '/company-management'
export const LICENCE_MANAGEMENT = '/licence-management'
export const GROUP_MANAGEMENT = '/group-management'
export const NOTIFICATION_MANAGEMENT = '/notification-management'
export const DEPARTMENT_MANAGEMENT = '/department-management'
export const NATIVE_APP_MANAGEMENT = '/native-app-management'
export const USER = '/users/:id'
export const COMPANY = '/company/:id'
export const DEPARTMENT = '/Departments/:id'
export const COURSES = '/Courses'
export const MODULE = '/Module/:id'
export const GSAT_COURSE = '/CourseOverview/GSAT'
export const GAMEVIEW = '/game-views'
export const COMPANION_APP  = '/companion-app-view'
export const COMPANION_APP_EDIT  = '/companion-edit/:id'

export const HELP_CENTER_SUPER_USER = '/help-center-super-user'
export const HELP_CENTER_COMPANY_ADMIN = '/help-center-company-admin'
export const HELP_CENTER_USER = '/help-center-user'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const ForgottenPassword = React.lazy(() => import ('./views/Auth/ForgottenPassword'))
const ForceSetPassword = React.lazy(() => import('./views/Auth/ForceResetPassword'))
const Login = React.lazy(() => import('./views/Auth/Login'))
const Actions = React.lazy(() => import('./components/Actions'))
const UserManagement = React.lazy(() => import('./views/UserManagement/UserManagement'))
const CompanyManagement = React.lazy(() => import('./views/CompanyManagment/CompanyManagement'))
const DepartmentManagment = React.lazy(() => import('./views/DepartmentManagment/DepartmentManagment'))
const User = React.lazy(() => import('./views/UserManagement/Users/User'))
const Company = React.lazy(() => import('./views/CompanyManagment/Company/Company'))
const LicenceManagment = React.lazy(() => import('./views/LicenceManagment/LicenceManagment'))
const Department = React.lazy(() => import('./views/DepartmentManagment/Department/Department'))
const ProfileSetup = React.lazy(() => import('./views/Auth/ProfileSetup'))
const MFAVerify = React.lazy(() => import('./views/Auth/MFAVerify'))
const MFACreation = React.lazy(() => import('./views/Auth/MFACreation'))
const PushNotification = React.lazy(() => import('./views/PushNotification/PushNotification'))
const Courses = React.lazy(() => import('./views/Courses/Courses'))
const GSATCourse = React.lazy(() => import('./views/CourseOverview/CourseOverview'))
const Module = React.lazy(() => import('./views/Module/Module'))
const GameView = React.lazy(() => import('./views/GameView/GameView'))
const CompanionApp = React.lazy(() => import('./views/CompanionApp/CompanionApp'))
const CompanionEditPage = React.lazy(() => import('./views/CompanionApp/CompanionEditPage'))

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: DASHBOARD, name: 'Dashboard', component: Dashboard },
  { path: SIGN_IN, name: 'Login', component: Login },
  { path: PASSWORD_FORGET, name: 'Forgotten Password', component: ForgottenPassword },
  { path: FORCE_SET_PASSWORD, name: 'Force Set Password', component: ForceSetPassword },
  { path: PROFILE_SETUP, name: 'Profile Setup', component: ProfileSetup },
  { path: ACTIONS, name: 'Action', component: Actions },
  { path: USER_MANAGEMENT, name: 'User Management', component: UserManagement },
  { path: COMPANY_MANAGEMENT, name: 'Company Management', component: CompanyManagement },
  { path: LICENCE_MANAGEMENT, name: 'Licence Management', component: LicenceManagment },
  { path: DEPARTMENT_MANAGEMENT, name: 'Department Management', component: DepartmentManagment },
  { path: USER, exact: true, name: 'User Details', component: User },
  { path: COMPANY, exact: true, name: 'Company Details', component: Company },
  { path: DEPARTMENT, exact: true, name: 'Department Details', component: Department },
  { path: MFA_SIGN_IN, exact: true, name: 'MFA Verify', component: MFAVerify },
  { path: MFA_SETUP, exact: true, name: 'MFA Creation', component: MFACreation },
  { path: NOTIFICATION_MANAGEMENT, name: 'Notifications Management', component: PushNotification },
  { path: COURSES, name: 'Courses', component: Courses },
  { path: GSAT_COURSE, name: 'GSAT', component: GSATCourse },
  { path: MODULE, name: 'Module', component: Module },
  { path: GAMEVIEW, name: 'Game View', component: GameView },
  { path: COMPANION_APP, name: 'Companion App', component: CompanionApp },
  { path: COMPANION_APP_EDIT, name: 'Companion App', component: CompanionEditPage },
]

export default routes
 