import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'
import Firebase, { FirebaseContext } from './components/Firebase'
import { CookiesContext } from './components/Cookies'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import Cookies from 'universal-cookie'
import * as MusculaLog from '@muscula.com/muscula-webapp-js-logger'


React.icons = icons

MusculaLog.Init('NB2sUIdQ')



// This adds a 
ReactDOM.render(
  <Provider store={store}>
    <CookiesContext.Provider value={new Cookies()}>
    <FirebaseContext.Provider value={new Firebase()}>
      <I18nextProvider i18n={i18n}>
        <App/>
      </I18nextProvider>
    </FirebaseContext.Provider>,
    </CookiesContext.Provider>
  </Provider>, 
  document.getElementById('root')
  
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
 
